import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./redux/slices/commonSlice";
import giftCardReducer from "./redux/slices/giftCardSlice";
import loyaltyReducer from "./redux/slices/loyaltySlice";
import cmsMyWalletReducer from "./redux/slices/cmsMyWalletSlice";
export const store = configureStore({
  reducer: {
    common: commonReducer,
    giftCard: giftCardReducer,
    loyalty: loyaltyReducer,
    cmsMyWallet: cmsMyWalletReducer
  },
});
export const getState = () => store.getState();
// Infer the `RootState` and `AppDispatch` types from the store itself

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
