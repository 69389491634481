import i18next from "i18next";
import {
  ACCEPTED_LANGUAGES_SHORT,
  LANGUAGE_BY_SHORT_LOCALES,
} from "../constants/language";

export const getLanguageByLocale = (locale: string) => {
  return LANGUAGE_BY_SHORT_LOCALES[
    locale.split("-")[0] as keyof typeof LANGUAGE_BY_SHORT_LOCALES
  ];
};
export const getCurrenti18nextLanguage = () => {
  return i18next.language;
};
export const changei18nextLanguage = async (language: string) => {
  i18next.changeLanguage(language);
};
export const isAcceptedLanguage = (locale: string) => {
  return ACCEPTED_LANGUAGES_SHORT.includes(locale.split("-")[0]);
};
