import { webApiPost } from "../api/httpApi";
import { BEConfig } from "../config/config";

export const fetchFaqsApi = async (params: {
  language: string;
  reqBody: object;
}) => {
  const {
    catalogApi: { bannerSections, baseURL, protocol, versionInfo },
  } = BEConfig;

  const apiURL = `${protocol}${baseURL}${versionInfo}${bannerSections}`;

  return webApiPost({ options: params.reqBody, url: apiURL }).request;
};
