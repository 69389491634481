import { webApiGet, webApiPost } from "../api/httpApi";
import { BEConfig } from "../config/config";

type LoyaltyRegistartionRequestBody = {
  countryId: number;
  customerId: number;
};

export const fetchLoyaltyDetailsApi = async (params: {
  countryId: string;
  currencyCode: string;
}) => {
  const {
    customerApi: { baseURL, protocol, versionInfo, loyaltyDetailsHandler },
  } = BEConfig;

  const apiURL = `${protocol}${baseURL}${versionInfo}${loyaltyDetailsHandler}?countryId=${params.countryId}&currencyCode=${params.currencyCode}`;
  return webApiGet({ url: apiURL }).request;
};

export const registerOnLoyaltyApi = async (
  reqBody: LoyaltyRegistartionRequestBody
) => {
  const {
    customerApi: { baseURL, protocol, versionInfo, registerOnLoyaltyHandler },
  } = BEConfig;

  const apiURL = `${protocol}${baseURL}${versionInfo}${registerOnLoyaltyHandler}`;
  return webApiPost({ options: reqBody, url: apiURL }).request;
};
