import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaqsApi, redeemCardApi } from "../../services/GiftRedeemService";
import { getState } from "../../store";

type Faq = {
  question: string;
  answers: string[];
};
type InitialState = {
  faq: Faq[];
  loading: boolean;
  giftCardSubmitting: boolean;
  error: string;
  hasError: boolean;
};
const initialState: InitialState = {
  faq: [],
  loading: false,
  giftCardSubmitting: false,
  error: "",
  hasError: false,
};
interface RedeemFormFields {
  cardNumber: string;
  pinCode: string;
}
export const redeemCard = createAsyncThunk(
  "giftCard/redeemCard",
  async (reqBody: RedeemFormFields) => {
    const response = await redeemCardApi(reqBody);
    return response.data;
  }
);
export const fetchFaqs = createAsyncThunk("giftCard/fetchFaqs", async () => {
  const response = await fetchFaqsApi({
    language: getState().common.language as string,
    countryId: getState().common.countryId as string,
  });
  return response;
});
const giftCardSlice = createSlice({
  name: "giftCard",
  initialState,
  reducers: {
    setFaq: (state, action: PayloadAction<Faq[]>) => {
      state.faq = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFaqs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchFaqs.fulfilled,
      (state, action: PayloadAction<Faq[]>) => {
        state.loading = false;
        state.faq = [...action.payload];
        state.error = "";
      }
    );
    builder.addCase(fetchFaqs.rejected, (state, action) => {
      state.loading = false;
      state.faq = [];
      state.error = action.error.message || "Something went wrong";
    });

    /* SUBMIT REDEEM COUPON  */
    builder.addCase(redeemCard.pending, (state) => {
      state.giftCardSubmitting = true;
      state.error = "";
      state.hasError = false;
    });
    builder.addCase(redeemCard.fulfilled, (state, action) => {
      state.giftCardSubmitting = false;
      if (!action.payload.success) {
        state.error = action.payload.message;
        state.hasError = true;
      } else {
        state.error = "";
        state.hasError = false;
      }
    });
    builder.addCase(redeemCard.rejected, (state, action) => {
      state.giftCardSubmitting = false;
      state.error = action.error.message || "Something went wrong";
      state.hasError = true;
    });
  },
});
// Action creators are generated for each case reducer function
export const { setFaq } = giftCardSlice.actions;

export default giftCardSlice.reducer;
