import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import RouteList from "./routes";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  setAccessToken,
  setCountryId,
  setCountrySlug,
  setCurrencyCode,
  setDevice,
  setLanguage,
  setLanguageShort,
  setLocale,
  setPlatform,
} from "./redux/slices/commonSlice";
import {
  changei18nextLanguage,
  getLanguageByLocale,
  isAcceptedLanguage,
} from "./utils/languageUtils";
import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_SHORT, DEFAULT_LOCALE } from "./constants/language";
import "./assets/styles/main.scss";

type Props = {};
const App = (props: Props) => {
  let [searchParams] = useSearchParams();
  const commonState = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  let _document = document.querySelector("html") as HTMLElement;

  const setBasicDetailsFromParams = async () => {
    dispatch(setCountryId(searchParams.get("country_id") as string));
    dispatch(setAccessToken(searchParams.get("t") as string));
    dispatch(setPlatform(searchParams.get("platform") as string));
    dispatch(setCurrencyCode(searchParams.get("currency_code") as string));
    dispatch(setDevice(searchParams.get("device") as string));

    if (searchParams.get("locale")) {
      const countrySlug = (searchParams.get("locale") as string).split("-")[1];
      dispatch(setCountrySlug(countrySlug));

      if (!isAcceptedLanguage(searchParams.get("locale") as string)) {
        dispatch(setLocale(DEFAULT_LOCALE));
        dispatch(setLanguage(DEFAULT_LANGUAGE));
        dispatch(setLanguageShort(DEFAULT_LANGUAGE_SHORT));
      } else {
        dispatch(setLocale(searchParams.get("locale") as string));
        dispatch(
          setLanguage(getLanguageByLocale(searchParams.get("locale") as string))
        );
        dispatch(
          setLanguageShort((searchParams.get("locale") as string).split("-")[0])
        );
      }
      _document.setAttribute(
        "dir",
        (searchParams.get("locale") as string).split("-")[0] === "ar"
          ? "rtl"
          : "ltr"
      );
      _document.setAttribute(
        "lang",
        (searchParams.get("locale") as string).split("-")[0]
      );
    } else {
      dispatch(setLocale(DEFAULT_LOCALE));
      dispatch(setLanguage(DEFAULT_LANGUAGE));
      dispatch(setLanguageShort(DEFAULT_LANGUAGE_SHORT));
    }
  };
  useEffect(() => {
    const checkBasicDetails = async () => await setBasicDetailsFromParams();
    checkBasicDetails();
  }, [searchParams]);

  useEffect(() => {
    changei18nextLanguage(commonState.locale as string);
  }, [commonState.locale]);
  return <RouteList />;
};

export default App;
