import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SECTION_SLUGS } from "../../constants/banner";
import { fetchFaqsApi } from "../../services/cmsMyWalletService";
import { getState } from "../../store";

type Faq = {
  content: object;
};

type Section = {
  sectionSlug: string;
  find: Function;
};

type FaqBodyData = {
  moduleType: string;
  slug: string;
};
type InitialState = {
  faq: Faq[];
  loading: boolean;
  error: string;
  hasError: boolean;
};
const initialState: InitialState = {
  faq: [],
  loading: false,
  error: "",
  hasError: false,
};

export const fetchFaqs = createAsyncThunk(
  "cmsMyWallet/fetchFaqs",
  async (reqBody: FaqBodyData) => {
    const response = await fetchFaqsApi({
      language: getState().common.language as string,
      reqBody,
    });
    return response;
  }
);
const cmsMyWalletSlice = createSlice({
  name: "cmsMyWallet",
  initialState,
  reducers: {
    setFaq: (state, action: PayloadAction<Faq[]>) => {
      state.faq = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFaqs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchFaqs.fulfilled,
      (state, action: PayloadAction<{ data: Section }>) => {
        state.loading = false;

        const faq = action.payload?.data?.find(
          (data: Section) => data?.sectionSlug === SECTION_SLUGS.MY_WALLET_FAQ
        );

        state.faq = [...faq?.content];

        state.error = "";
      }
    );
    builder.addCase(fetchFaqs.rejected, (state, action) => {
      state.loading = false;
      state.faq = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});
// Action creators are generated for each case reducer function
export const { setFaq } = cmsMyWalletSlice.actions;

export default cmsMyWalletSlice.reducer;
