export const config = {
  development: {
    parentWebURL:
      process.env.REACT_APP_PARENT_WEB_URL || "http://10.10.31.73:3000",
  },
  uat: {
    parentWebURL: process.env.REACT_APP_PARENT_WEB_URL,
  },
  qa: {
    parentWebURL: process.env.REACT_APP_PARENT_WEB_URL,
  },
  production: {
    parentWebURL: process.env.REACT_APP_PARENT_WEB_URL,
  },
};

export const BEConfig = {
  customerApi: {
    protocol: process.env.REACT_APP_PROTOCOL,
    baseURL: process.env.REACT_APP_CUSTOMER_API_BASE_URL,
    versionInfo: "/api/v3/",
    redeemCardHandler: "account/redeem-gift-voucher",
    loyaltyDetailsHandler: "account/loyalty-details",
    registerOnLoyaltyHandler: "account/loyalty-registration",
  },
  catalogApi: {
    protocol: process.env.REACT_APP_PROTOCOL || "https://",
    port: process.env.CATALOG_PORT || "",
    baseURL:
      process.env.REACT_APP_CATALOG_API_BASE_URL ||
      "qa-api.bfl.dev/catalog-api",
    versionInfo: "/api/v6/",
    bannerSections: "sections",
  },
};

const environment = process.env.ENV || "development";

export const ENV_CONFIG = config[environment as keyof typeof config];
