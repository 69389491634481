import axios from "axios";
import { WebApiParams } from "./httpApi.type";
import { getState } from "../store";

function getConfig() {
  const header = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/json",
    "x-access-token": getState().common.accessToken,
    "x-language": getState()?.common.languageShort || "en",
    "x-countryid": getState()?.common.countryId || "",
    "x-currency-code": getState()?.common.currencyCode || "AED",
    "x-platform": getState()?.common.platform || "web",
    "x-device": getState()?.common.device,
  };
  const requestHeaders = {
    ...header,
  };

  const config = {
    headers: requestHeaders,
  };

  return {
    config,
  };
}
export function webApiGet(data: WebApiParams) {
  return {
    request: axios.get(data.url, getConfig().config),
  };
}

export function webApiGetWithParams(data: WebApiParams) {
  return {
    request: axios.get(data.url, { params: data.options }),
  };
}

export function webApiPut(data: WebApiParams) {
  return {
    request: axios.put(data.url, data.options, getConfig().config),
  };
}
export function webApiPost(data: WebApiParams) {
  return {
    request: axios.post(data.url, data.options, getConfig().config),
  };
}

export function webApiDelete(data: WebApiParams) {
  return {
    request: axios.delete(data.url, getConfig().config),
  };
}
