import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  AboutPage,
  CmsMyWalletFaqSection,
  HomePage,
  NotFoundPage,
  RedeemGiftCardPage,
} from "../pages";
import loaderStyles from "../components/loader/Loader.module.scss";
import BounceLoader from "../components/loader/bounceLoader";
type Props = {};
const Loading = () => (
  <div className={loaderStyles.pageLoaderContainer}>
    <BounceLoader color="#f8d000" size={10} qty={3} />
  </div>
);
const Index = (props: Props) => {
  const routeList = useRoutes([
    {
      path: "/",
      element: (
        <Suspense fallback={<Loading />}>
          <HomePage />{" "}
        </Suspense>
      ),
    },
    {
      path: "redeem-gift-card",
      element: (
        <Suspense fallback={<Loading />}>
          <RedeemGiftCardPage />
        </Suspense>
      ),
    },
    {
      path: "about",
      element: (
        <Suspense fallback={<Loading />}>
          <AboutPage />
        </Suspense>
      ),
    },
    {
      path: "cms-wallet-faq-accordion",
      element: (
        <Suspense fallback={<Loading />}>
          <CmsMyWalletFaqSection />
        </Suspense>
      ),
    },
    {
      path: "notfound",
      element: (
        <Suspense fallback={<Loading />}>
          <NotFoundPage />
        </Suspense>
      ),
    },
    { path: "*", element: <Navigate to={"/"} /> },
  ]);

  return routeList;
};

export default Index;
