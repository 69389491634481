import React from "react";
import styles from "./BounceLoader.module.scss";
import { BounceLoaderProps } from "./BounceLoader.type";

const BounceLoader = (props: BounceLoaderProps) => {
  const { qty, size, color } = props;
  const getBounceClassName = (index: any) => {
    return 'bounce' + index.toString();
  }
  return (
    <div className={styles.bounce}>
      {Array.from({ length: qty }, (_, i) => (
        <div
          className={styles[`${getBounceClassName(i)}`]}
          style={{ width: size, height: size, backgroundColor: color }}
          key={i}
        />
      ))}
    </div>
  );
};

export default BounceLoader;
