export const BACKGROUND_TYPE = {
  GRADIENT: "backgroundGradient",
  COLOR: "backgroundColor",
  IMAGE: "backgroundImage",
};

export const HOMEPAGE_SECTION_TYPES = {
  ACCORDION: "Accordion",
};

export const BANNER_ROW_TYPE = {
  VIDEO: "video",
  COUNTDOWN: "countdown",
};

export const MODULE_SLUGS = {
  MY_ACCOUNT_FAQ: "my-account",
};

export const SECTION_SLUGS = {
  MY_WALLET_FAQ: "my-wallet-faq",
};
