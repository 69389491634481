import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface CommonState {
  accessToken: string;
  locale: string;
  language: string;
  languageShort: string;
  countryId: string;
  platform: string;
  device: string;
  currencyCode: string;
  isAuthenticated: boolean;
  isLoadingContent: boolean;
  isLoadingPage: boolean;
  countrySlug: string;
}
const initialState: CommonState = {
  accessToken: "",
  locale: "en-ae",
  language: "english",
  languageShort: "en",
  countryId: "",
  platform: "web",
  device: "desktop",
  currencyCode: "",
  isAuthenticated: true,
  isLoadingContent: false,
  isLoadingPage: false,
  countrySlug: "ae",
};
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setLanguageShort: (state, action: PayloadAction<string>) => {
      state.languageShort = action.payload;
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setCountryId: (state, action: PayloadAction<string>) => {
      state.countryId = action.payload;
    },
    setPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload;
    },
    setDevice: (state, action: PayloadAction<string>) => {
      state.device = action.payload;
    },
    setCurrencyCode: (state, action: PayloadAction<string>) => {
      state.currencyCode = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsLodingContent: (state, action: PayloadAction<boolean>) => {
      state.isLoadingContent = action.payload;
    },
    setisLoadingPage: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPage = action.payload;
    },
    setCountrySlug: (state, action: PayloadAction<string>) => {
      state.countrySlug = action.payload;
    },
  },
});
export const {
  setAccessToken,
  setLanguage,
  setLanguageShort,
  setLocale,
  setCountryId,
  setPlatform,
  setDevice,
  setCurrencyCode,
  setIsAuthenticated,
  setIsLodingContent,
  setisLoadingPage,
  setCountrySlug,
} = commonSlice.actions;
export default commonSlice.reducer;
