import { webApiPost } from "../api/httpApi";
import { BEConfig } from "../config/config";
import giftCardRedeemFaq from "../contents/giftCardRedeemFaq.json";

type RedeemCardRequestBody = {
  cardNumber: string;
  pinCode: string;
};
export const fetchFaqsApi = async (params: {
  language: string;
  countryId: string;
}) => {
  return giftCardRedeemFaq[params.language as keyof typeof giftCardRedeemFaq];
};
export const redeemCardApi = async (reqBody: RedeemCardRequestBody) => {
  const {
    customerApi: { baseURL, protocol, versionInfo, redeemCardHandler },
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${versionInfo}${redeemCardHandler}`;
  return webApiPost({ options: reqBody, url: apiURL }).request;
};
