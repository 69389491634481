import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchLoyaltyDetailsApi,
  registerOnLoyaltyApi,
} from "../../services/LoyaltyService";
import { getState } from "../../store";

type InitialState = {
  loyaltyDetails: object;
  loading: boolean;
  registeringToLoayalty: boolean;
  fetchLoyaltyDetails: boolean;
  error: string;
  loyaltyRegistrationError: string;
  hasError: boolean;
};
const initialState: InitialState = {
  loyaltyDetails: {},
  loading: false,
  registeringToLoayalty: false,
  fetchLoyaltyDetails: false,
  error: "",
  loyaltyRegistrationError: "",
  hasError: false,
};
type LoyaltyRegistartionRequestBody = {
  countryId: number;
  customerId: number;
};
export const registerToLoyalty = createAsyncThunk(
  "loyalty/registerToLoyalty",
  async (reqBody: LoyaltyRegistartionRequestBody) => {
    const response = await registerOnLoyaltyApi(reqBody);
    return response.data;
  }
);
export const fetchLoyaltyDetails = createAsyncThunk(
  "loyalty/fetchLoyaltyDetails",
  async () => {
    const response = await fetchLoyaltyDetailsApi({
      currencyCode: getState().common.currencyCode as string,
      countryId: getState().common.countryId as string,
    });
    return response.data;
  }
);
const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoyaltyDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLoyaltyDetails.fulfilled, (state, action) => {
      state.fetchLoyaltyDetails = false;
      state.loading = false;
      if (!action.payload.success) {
        state.error = action.payload.message;
        state.hasError = true;
      } else {
        state.error = "";
        state.hasError = false;
        state.loyaltyDetails = { ...action.payload.loyaltyDetails };
      }
    });
    builder.addCase(fetchLoyaltyDetails.rejected, (state, action) => {
      state.loading = false;
      state.loyaltyDetails = {};
      state.error = action.error.message || "Something went wrong";
    });
    /* LOYALTY REGISTRATION */
    builder.addCase(registerToLoyalty.pending, (state) => {
      state.registeringToLoayalty = true;
      state.hasError = false;
      state.loyaltyRegistrationError = "";
    });
    builder.addCase(registerToLoyalty.fulfilled, (state, action) => {
      state.registeringToLoayalty = false;
      if (!action.payload.success) {
        state.hasError = true;
        state.loyaltyRegistrationError = action.payload.message;
      } else {
        state.hasError = false;
        state.loyaltyRegistrationError = "";
      }
    });
    builder.addCase(registerToLoyalty.rejected, (state, action) => {
      state.registeringToLoayalty = false;
      state.hasError = false;
      state.loyaltyRegistrationError =
        action.error.message || "Something went wrong";
    });
  },
});
export default loyaltySlice.reducer;
